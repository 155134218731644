import { render, staticRenderFns } from "./DetailsRow.vue?vue&type=template&id=c6f13a9a&scoped=true"
var script = {}
import style0 from "./DetailsRow.vue?vue&type=style&index=0&id=c6f13a9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6f13a9a",
  null
  
)

export default component.exports